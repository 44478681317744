<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()
const orderNumber = useRouteQuery<string>('orderNumber')

defineOptions({
  name: 'GenericPageCinemaConcessionShop',
})
</script>

<template>
  <div class="contents">
    <CinemaBanner :cinema page="SHOP" class="mb-8" />
    <ConcessionShop :cinema :order-number />
  </div>
</template>
