<script setup lang="ts">
import type { Cinema } from '#gql/default'
import type { Panel } from '@booking/types/panel-manager'
import { CONCESSION_SHOP_PANEL } from '@concession-shop/constants/concession-shop'

interface Props {
  cinema: Cinema
  type?: 'SHOPITEM'
  orderNumber?: string
}

const { CART, CHECKOUT, SELECT } = CONCESSION_SHOP_PANEL
const { cinema, type = 'SHOPITEM', orderNumber } = defineProps<Props>()
const { t } = useI18n()

const { isInitialised } = await useConcessionShop({ cinema, type, orderNumber })
const { cart } = await useConcessionCart({ cinema })

const {
  step,
  panels,
  activePanel,
  setPanel,
  setInitialPanel,
  initializePanels,
  isInitialised: isPanelsInitialized,
} = useConcessionShopPanels()

// Initialize panels separately
initializePanels([
  {
    key: SELECT,
    title: t('panels.select.title'),
    hideNav: true,
  },
  {
    key: CART,
    title: t('panels.cart.title'),
  },
  {
    key: CHECKOUT,
    title: t('panels.checkout.title'),
  },
] as Panel[])

defineOptions({
  name: 'ConcessionShop',
})
</script>

<template>
  <div v-if="isInitialised" class="contents">
    <PanelManager>
      <template #navigation="{ step: panelStep }">
        <PanelNavigation
          :panels="panels"
          :active-panel="activePanel"
          :step="panelStep"
          :set-panel="setPanel"
          :set-initial-panel="setInitialPanel"
        />
      </template>
      <template #panels="{ step: panelStep }">
        <ConcessionShopPanelSelect v-if="panelStep === SELECT" :cinema />
        <ConcessionShopPanelCart v-if="panelStep === CART" :cinema />
        <ConcessionShopPanelCheckout v-if="panelStep === CHECKOUT" />

        <CartFab
          v-if="panelStep === CHECKOUT && cart"
          @click="setPanel(CART)"
        />
      </template>
    </PanelManager>
  </div>
</template>

<i18n>
de:
  noConcessionsAvailable: "Leider sind aktuell keine Concessionen verfügbar."
  panels:
    cart:
      title: "Warenkorb"
    checkout:
      title: "Kasse"
    select:
      title: "Shop"
es:
  noConcessionsAvailable: "Lo sentimos, no hay Concessiones disponibles."
  panels:
    cart:
      title: "Carrito"
    checkout:
      title: "Pagar"
    select:
      title: "Shop"
</i18n>
